import { ErrorBoundary as SErrorBoundary } from '@sentry/nextjs'
import Page from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'
import { Flex } from '@pancakeswap/uikit'
import { useCallback } from 'react'
import { Button } from 'components/ui/button'

export function SentryErrorBoundary({ children }) {
  const { t } = useTranslation()
  const handleOnClick = useCallback(() => window.location.reload(), [])
  return (
    <SErrorBoundary
      beforeCapture={(scope) => {
        scope.setLevel('fatal')
      }}
      fallback={({ eventId }) => {
        return (
          <Page>
            <Flex className="w-full h-[70vh]" flexDirection="column" justifyContent="center" alignItems="center">
              {/* <LogoIcon width="64px" mb="8px" /> */}
              {/* <Text mb="16px">{t()}</Text>
              {eventId && (
                <Flex flexDirection="column" style={{ textAlign: 'center' }} mb="8px">
                  <Text>{t('Error Tracking Id')}</Text>
                  <Flex alignItems="center">
                    <Text>{eventId}</Text>
                    <IconButton variant="text" onClick={() => copyText(eventId)}>
                      <CopyIcon color="primary" width="24px" />
                    </IconButton>
                  </Flex>
                </Flex>
              )}
              <Button onClick={handleOnClick}>{t('Click here to reset!')}</Button> */}
              <div className="flex flex-col items-center justify-center gap-8">
                <span className="text-xl font-bold text-black">Oops, something wrong.</span>
                <Button className="rounded-full bg-black text-orange-200 hover:bg-black" onClick={handleOnClick}>
                  {t('Click here to reset!')}
                </Button>
              </div>
            </Flex>
          </Page>
        )
      }}
    >
      {children}
    </SErrorBoundary>
  )
}
