import {
  CloseButton,
  createTheme,
  DEFAULT_THEME,
  defaultVariantColorsResolver,
  mergeMantineTheme,
  Modal,
  Popover,
  VariantColorsResolver,
} from '@mantine/core'
import { colors } from './colors'

const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input)

  if (input.variant === 'filled-outline') {
    const outlineColors = defaultVariantColorsResolver({ ...input, variant: 'outline' })
    return {
      ...outlineColors,
      background: 'var(--mantine-color-white)',
      hover: outlineColors.color,
      hoverColor: 'var(--mantine-color-white)',
    }
  }

  return defaultResolvedColors
}

const themeOverride = createTheme({
  colors,
  primaryColor: 'lime',
  primaryShade: 9,
  variantColorResolver,
  defaultRadius: 6,
  components: {
    CloseButton: CloseButton.extend({
      defaultProps: {
        variant: 'transparent',
      },
    }),
    Modal: Modal.extend({
      defaultProps: {
        centered: true,
        radius: 'lg',
        overlayProps: {
          backgroundOpacity: 0,
          blur: 10,
        },
      },
    }),
    Popover: Popover.extend({
      defaultProps: {
        shadow: 'sm',
      },
    }),
  },
})

export const theme = mergeMantineTheme(DEFAULT_THEME, themeOverride)
