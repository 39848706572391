import { Grid, Modal } from '@pancakeswap/uikit'
import { useLocalNetworkChain } from 'hooks/useActiveChainId'
import { useTranslation } from '@pancakeswap/localization'
import { useSwitchNetwork, useSwitchNetworkLocal } from 'hooks/useSwitchNetwork'
import useAuth from 'hooks/useAuth'
import { useMenuItems } from 'components/Menu/hooks/useMenuItems'
import { useRouter } from 'next/router'
import { getActiveMenuItem, getActiveSubMenuItem } from 'components/Menu/utils'
import { useAccount, useNetwork } from 'wagmi'
import { useMemo } from 'react'
import { ChainId } from '@pancakeswap/sdk'
import { Button } from 'components/ui/button'
import Dots from '../Loader/Dots'

// Where chain is not supported or page not supported
export function UnsupportedNetworkModal({ pageSupportedChains }: { pageSupportedChains: number[] }) {
  const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
  const switchNetworkLocal = useSwitchNetworkLocal()
  const { chains } = useNetwork()
  const chainId = useLocalNetworkChain() || ChainId.RSS3
  const { isConnected } = useAccount()
  const { logout } = useAuth()
  const { t } = useTranslation()
  const menuItems = useMenuItems()
  const { pathname } = useRouter()

  const title = useMemo(() => {
    const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
    const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

    return activeSubMenuItem?.label || activeMenuItem?.label
  }, [menuItems, pathname])
  const supportedMainnetChains = useMemo(
    () => chains.filter((chain) => !chain.testnet && pageSupportedChains?.includes(chain.id)),
    [chains, pageSupportedChains],
  )

  return (
    <Modal title="Check your network" hideCloseButton>
      <Grid style={{ gap: '16px' }} maxWidth="336px">
        <span className="text-orange-200 text-left text-balance text-sm max-w-[70%] opacity-60">
          {t('Currently %feature% only supported in', { feature: typeof title === 'string' ? title : 'this page' })}{' '}
          {supportedMainnetChains?.map((c) => c.name).join(', ')}
        </span>

        <div className="pt-16 flex flex-col gap-4 w-full">
          {canSwitch ? (
            <Button
              className="bg-orange-200 text-black"
              size="lg"
              onClick={() => {
                if (supportedMainnetChains.map((c) => c.id).includes(chainId)) {
                  switchNetworkAsync(chainId)
                } else {
                  switchNetworkAsync(ChainId.RSS3)
                }
              }}
            >
              {isLoading ? <Dots>Switch network in wallet</Dots> : 'Switch network in wallet'}
            </Button>
          ) : (
            <span className="text-orange-200">Unable to switch network. Please try it on your wallet</span>
          )}
          {isConnected && (
            <Button
              className="border-orange-200 text-orange-200"
              variant="outline"
              size="lg"
              onClick={() =>
                logout().then(() => {
                  switchNetworkLocal(ChainId.RSS3)
                })
              }
            >
              Disconnect Wallet
            </Button>
          )}
        </div>
      </Grid>
    </Modal>
  )
}
