import { ModalProvider, light, dark, UIKitProvider } from '@pancakeswap/uikit'
import { MantineProvider } from '@mantine/core'
import { Provider } from 'react-redux'
import { SWRConfig } from 'swr'
import { LanguageProvider } from '@pancakeswap/localization'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { fetchStatusMiddleware } from 'hooks/useSWRContract'
import { Store } from '@reduxjs/toolkit'
import { ThemeProvider as NextThemeProvider, useTheme as useNextTheme } from 'next-themes'
import { WagmiProvider } from '@pancakeswap/wagmi'
import { client } from 'utils/wagmi'
import { HistoryManagerProvider } from 'contexts/HistoryContext'
import { theme } from 'utils/mantineTheme'
import { ThemeProvider } from 'components/ThemeProvider'

const StyledUIKitProvider: React.FC<React.PropsWithChildren> = ({ children, ...props }) => {
  const { resolvedTheme } = useNextTheme()
  return (
    <UIKitProvider theme={resolvedTheme === 'dark' ? dark : light} {...props}>
      {children}
    </UIKitProvider>
  )
}

const Providers: React.FC<React.PropsWithChildren<{ store: Store; children: React.ReactNode }>> = ({
  children,
  store,
}) => {
  const queryClient = new QueryClient()
  return (
    <ThemeProvider attribute="class" defaultTheme="dark" disableTransitionOnChange>
      <QueryClientProvider client={queryClient}>
        <WagmiProvider client={client}>
          <Provider store={store}>
            <MantineProvider theme={theme}>
              <NextThemeProvider forcedTheme="light">
                <StyledUIKitProvider>
                  <LanguageProvider>
                    <SWRConfig
                      value={{
                        use: [fetchStatusMiddleware],
                      }}
                    >
                      <HistoryManagerProvider>
                        <ModalProvider>{children}</ModalProvider>
                      </HistoryManagerProvider>
                    </SWRConfig>
                  </LanguageProvider>
                </StyledUIKitProvider>
              </NextThemeProvider>
            </MantineProvider>
          </Provider>
        </WagmiProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default Providers
