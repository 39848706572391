import { ChainId } from '@pancakeswap/sdk'

export const SUPPORT_ONLY_BSC = [ChainId.BSC, ChainId.X1_TESTNET, ChainId.RSS3]

export const SUPPORT_FARMS = [
  ChainId.BSC,
  ChainId.BSC_TESTNET,
  ChainId.ETHEREUM,
  ChainId.GOERLI,
  ChainId.X1_TESTNET,
  ChainId.RSS3,
]

export const SUPPORT_ZAP = [ChainId.BSC, ChainId.BSC_TESTNET]
